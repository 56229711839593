import React from "react";

import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import DepositNav from "Screens/DepositNav";
import AchDepositStack from "./AchDepositStack";
import CreditCardDepositStack from "./CreditCardDepositStack";
import { getStandardScreenOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator screenOptions={getStandardScreenOptions({ navigation })}>
      <Stack.Screen name="DepositNav" component={DepositNav} />
      <Stack.Screen name="AchDepositStack" component={AchDepositStack} />
      <Stack.Screen
        name="CreditCardDepositStack"
        component={CreditCardDepositStack}
      />
    </Stack.Navigator>
  );
};
