import React from "react";

import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import DepositStack from "./DepositStack";
import WithdrawStack from "./WithdrawStack";
import WalletNav from "Screens/WalletNav";
import { getStandardScreenOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={getStandardScreenOptions({ screenOptions, navigation })}
    >
      <Stack.Screen name="WalletNav" component={WalletNav} />
      <Stack.Screen name="DepositStack" component={DepositStack} />
      <Stack.Screen name="WithdrawStack" component={WithdrawStack} />
    </Stack.Navigator>
  );
};
