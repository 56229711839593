import { useState, useEffect } from "react";
import { ALLOWED_STATES } from "shared/Config";

export default () => {
  const [location, setLocation] = useState(null);
  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then(response => response.json())
      .then(json =>
        setLocation({
          ...json,
          isAllowedToDeposit:
            ALLOWED_STATES.includes(json?.region) || json?.country === "CA",
        })
      );
  }, [setLocation]);

  return location;
};
