import React, { useContext, useCallback } from "react";
import { Button, HelperText, useTheme, TextInput } from "react-native-paper";
import { CurrentUserContext } from "Contexts/CurrentUser";

import * as yup from "yup";
import Screen from "Components/Screen";

import valid from "card-validator"; //import statement
import { TextInputMask } from "react-native-masked-text";

import { HeaderButtons, Item } from "react-navigation-header-buttons";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Platform, ScrollView } from "react-native";
import environment from "../environment";
import ScreenBottom from "Components/Screen/Bottom";
import { gql, useMutation } from "@apollo/client";

// const [createTransaction] = useCreditCardTransaction({
//   bsContext: bs,
//   skip: !isValid,
//   cardTransactionType: "AUTH_ONLY",
//   amount: 1,
//   firstName,
//   lastName,
//   zip: postalCode,
//   userId: currentUser?.id,
//   expirationMonth: (expirationDate ?? "").split("/")[0],
//   expirationYear: `20${(expirationDate ?? "").split("/")[1]}`,
//   cardNumber: number,
//   securityCode: cvv,
//   cardType,
// });

const isTest = environment?.stage !== "production";

const paylianceSavePaymentMethodMutation = gql`
  mutation PaylianceSavePaymentMethod(
    $input: PaylianceSavePaymentMethodInput!
  ) {
    paylianceSavePaymentMethod(input: $input) {
      cardId
    }
  }
`;

const schema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(25, "Too long - should be 25 chars maximum.")
    .matches(/^[a-z ,.'-]+$/i, "Must be a valid first name")
    .required("Can't be blank"),
  lastName: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(25, "Too long - should be 25 chars maximum.")
    .matches(/^[a-z ,.'-]+$/i, "Must be a valid last name")
    .required("Can't be blank"),
  number: yup
    .string()
    .test(
      "test-number", // this is used internally by yup
      "Credit Card number is invalid", //validation message
      value => !!isTest || valid.number(value).isValid
    ) // return true false based on validation
    .required(),
  expirationDate: yup
    .string()
    .test(
      "test-number", // this is used internally by yup
      "Expiration Date is invalid", //validation message
      value => valid.expirationDate(value).isValid
    ) // return true false based on validation
    .required(),
  cvv: yup
    .string()
    .min(3, "CVV is invalid")
    .max(4, "CVV is invalid")
    // .test('test-number', // this is used internally by yup
    // 'CVV is invalid', //validation message
    // value => valid.cvv(value, (value??"").length <= 3 ? 3 : 4).isValid) // return true false based on validation
    .required(),
  postalCode: yup
    .string()
    .test(
      "test-number", // this is used internally by yup
      "Zipcode is invalid", //validation message
      value => valid.postalCode(value).isValid
    ) // return true false based on validation
    .required(),
});

export default ({ navigation, route }) => {
  const theme = useTheme();
  const currentUser = useContext(CurrentUserContext);
  const inputs = React.useRef({});

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setError,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const number = watch("number", "");
  const expirationDate = watch("expirationDate", "");
  // const cvv = watch("cvv", "");
  const postalCode = watch("postalCode", "");
  const firstName = watch("firstName", "");
  const lastName = watch("lastName", "");

  const cardType = valid.number(number)?.card ?? "";
  const cardTypeForMask = /american/i.test(cardType?.type ?? "")
    ? "amex"
    : /diner/.test(cardType)
    ? "diners"
    : "visa-or-mastercard";

  const [savePaymentMethod] = useMutation(paylianceSavePaymentMethodMutation, {
    variables: {
      input: {
        cardNumber: number.replace(/\D/g, ""),
        expirationDate: expirationDate.replace(/\D/g, ""),
        firstName,
        lastName,
        zipCode: postalCode,
      },
    },
    refetchQueries: [
      "CardSelectPaylianceListMyPaymentMethods",
      "PaylianceListMyPaymentMethods",
    ],
  });

  // const savePaymentMethod = useGidxAddPaymentMethod({
  //   type: "CC",
  //   cvv,
  //   fullName: `${firstName} ${lastName}`,
  //   cardNumber: number,
  //   expirationDate,
  //   postalCode,
  // });

  const handleSubmitError = useCallback(
    error =>
      console.error(error) ||
      setError("number", {
        ...error,
        message: `${error.code ?? ""} - ${error.message}`,
      }),
    [setError]
  );

  const onSubmit = useCallback(
    () =>
      savePaymentMethod()
        .then(() => navigation.goBack())
        .catch(handleSubmitError),
    [savePaymentMethod, handleSubmitError, navigation]
  );

  const doSubmit = handleSubmit(onSubmit);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtons size={48}>
          <Item
            title="Save"
            disabled={!isValid || !!isSubmitting}
            onPress={doSubmit}
          />
        </HeaderButtons>
      ),
    });
  }, [navigation, doSubmit, isValid, isSubmitting]);

  return (
    <Screen
      gatedTo="member"
      title="Add Card"
      fullHeight
      fullWidth
      hasKeyboard
      backgroundColor={theme.colors.surface}
    >
      <ScrollView
        contentContainerStyle={{ marginHorizontal: theme.spacing.xl }}
        style={{ flex: 1 }}
      >
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoFocus
                autoCorrect={false}
                autoCapitalize="words"
                ref={e => (inputs.current["firstName"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "name"
                }
                keyboardType="default"
                textContentType="givenName"
                label="First Name"
                error={!!errors.firstName}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.lastName.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value ?? ""}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.firstName}>
                {errors.firstName?.message}
              </HelperText>
            </>
          )}
          name="firstName"
          defaultValue={currentUser?.attributes?.given_name}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="words"
                ref={e => (inputs.current["lastName"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "name"
                }
                keyboardType="default"
                textContentType="familyName"
                label="Last Name"
                error={!!errors.lastName}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.number.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.lastName}>
                {errors.lastName?.message}
              </HelperText>
            </>
          )}
          name="lastName"
          defaultValue={currentUser?.attributes?.family_name}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["number"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "cc-number"
                }
                keyboardType="number-pad"
                textContentType="none"
                label="Card Number"
                error={!!errors.number}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.expirationDate.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
                render={props => (
                  <TextInputMask
                    {...props}
                    type="credit-card"
                    options={{
                      obfuscated: false,
                      issuer: cardTypeForMask,
                    }}
                  />
                )}
              />
              <HelperText type="error" visible={!!errors.number}>
                {errors.number?.message}
              </HelperText>
            </>
          )}
          name="number"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["expirationDate"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "cc-exp"
                }
                keyboardType="number-pad"
                textContentType="none"
                label="Expiration Date"
                error={!!errors.expirationDate}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.cvv.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
                render={props => (
                  <TextInputMask
                    {...props}
                    type="custom"
                    options={{
                      mask: "99/99",
                    }}
                  />
                )}
              />
              <HelperText type="error" visible={!!errors.expirationDate}>
                {errors.expirationDate?.message}
              </HelperText>
            </>
          )}
          name="expirationDate"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                maxLength={cardTypeForMask === "amex" ? 4 : 3}
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["cvv"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "cc-csc"
                }
                keyboardType="number-pad"
                textContentType="none"
                label="CVV"
                error={!!errors.cvv}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.postalCode.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.cvv}>
                {errors.cvv?.message}
              </HelperText>
            </>
          )}
          name="cvv"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                maxLength={6}
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["postalCode"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "postal-code"
                }
                // keyboardType="number-pad"
                textContentType="postalCode"
                label="Zip/Postal Code"
                error={!!errors.postalCode}
                returnKeyLabel="Done"
                returnKeyType="done"
                onSubmitEditing={doSubmit}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.postalCode}>
                {errors.postalCode?.message}
              </HelperText>
            </>
          )}
          name="postalCode"
          defaultValue=""
        />
      </ScrollView>

      {/* <Text>{environment?.stage}</Text>
      <Text>{environment?.bluesnap_encryption_key}</Text> */}

      <ScreenBottom adjustPadding inset>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          {isSubmitting ? "Please Wait..." : "Save Card"}
        </Button>
      </ScreenBottom>
    </Screen>
  );
};
