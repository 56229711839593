import PaylianceDepositSummary from "Components/Payliance/DepositSummary";

export default ({ navigation, route }) => {
  return (
    <PaylianceDepositSummary
      navigation={navigation}
      type="cc"
      amount={route?.params?.amount ?? "25.00"}
      lastFour={route?.params?.lastFour}
      promotionId={route?.params?.promotionId}
    />
  );
};
